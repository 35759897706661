var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("div", { staticClass: "row mainchild" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 메뉴 이동하기 "),
              ]),
              _c("div", { staticClass: "card-body nopadgrid card-body-180" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/mdm/pjm/projectManage")
                            },
                          },
                        },
                        [
                          _c(
                            "q-img",
                            {
                              staticClass: "image-thumbnail",
                              staticStyle: {
                                height: "145px",
                                "max-width": "180px",
                                "border-radius": "15px",
                              },
                              attrs: {
                                src: require("@/assets/images/accident2.jpg"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1 h5",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("accessible")]
                                        ),
                                        _vm._v(" 공사현장"),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/saas/riskAssessList")
                            },
                          },
                        },
                        [
                          _c(
                            "q-img",
                            {
                              staticClass: "image-thumbnail",
                              staticStyle: {
                                height: "145px",
                                "max-width": "180px",
                                "border-radius": "15px",
                              },
                              attrs: {
                                src: require("@/assets/images/improve2.jpg"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1 h5",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("warning")]
                                        ),
                                        _vm._v(" 위험성평가"),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          "txt-box-grid text-red-box",
                                          "text-position",
                                        ],
                                      },
                                      [_vm._v(_vm._s("3건"))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sop/swp/safeWorkPermit")
                            },
                          },
                        },
                        [
                          _c(
                            "q-img",
                            {
                              staticClass: "image-thumbnail",
                              staticStyle: {
                                height: "145px",
                                "max-width": "180px",
                                "border-radius": "15px",
                              },
                              attrs: {
                                src: require("@/assets/images/work2.jpg"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1 h5",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("handyman")]
                                        ),
                                        _vm._v(" 작업허가"),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          "txt-box-grid text-red-box",
                                          "text-position",
                                        ],
                                      },
                                      [_vm._v(_vm._s("금일: " + "5건"))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sai/tbm/tbm")
                            },
                          },
                        },
                        [
                          _c(
                            "q-img",
                            {
                              staticClass: "image-thumbnail",
                              staticStyle: {
                                height: "145px",
                                "max-width": "180px",
                                "border-radius": "15px",
                              },
                              attrs: {
                                src: require("@/assets/images/tbm2.jpg"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1 h5",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("engineering")]
                                        ),
                                        _vm._v(" TBM"),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          "txt-box-grid text-red-box",
                                          "text-position",
                                        ],
                                      },
                                      [_vm._v(_vm._s("금일: " + "3건"))]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sai/safetycheck/safetyCheck")
                            },
                          },
                        },
                        [
                          _c(
                            "q-img",
                            {
                              staticClass: "image-thumbnail",
                              staticStyle: {
                                height: "145px",
                                "max-width": "180px",
                                "border-radius": "15px",
                              },
                              attrs: {
                                src: require("@/assets/images/safetycheck2.jpg"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1 h5",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("query_stats")]
                                        ),
                                        _vm._v(" 안전순찰"),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          "txt-box-grid text-red-box",
                                          "text-position",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            Number(_vm.data.safetyFlag) === 0
                                              ? "금일: 점검필요"
                                              : "금일: 점검완료"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sop/iim/near/nearAccident")
                            },
                          },
                        },
                        [
                          _c(
                            "q-img",
                            {
                              staticClass: "image-thumbnail",
                              staticStyle: {
                                height: "145px",
                                "max-width": "180px",
                                "border-radius": "15px",
                              },
                              attrs: {
                                src: require("@/assets/images/education2.jpg"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1 h5",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("accessible")]
                                        ),
                                        _vm._v(" 아차사고"),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 업체별 투입인력 "),
              ]),
              _c("div", { staticClass: "card-body nopadgrid card-body-180" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.vendorInfo.vendors, function (item, index) {
                    return _c(
                      "div",
                      { key: index, class: index == 0 ? "col-12" : "col-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "man-card-cnt2 text-white",
                            class: _vm.bgColorItem[index],
                          },
                          [
                            _vm._v(" " + _vm._s(item.vendorName) + " "),
                            _c("div", { staticClass: "man-card-cnt-man2" }, [
                              _vm._v(_vm._s(item.vendorCount + "명")),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 부적합 개선현황 "),
                _c(
                  "div",
                  {
                    staticClass: "card-more card-more-txt",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/ibm/impr")
                      },
                    },
                  },
                  [_vm._v("상세보기")]
                ),
              ]),
              _c("div", { staticClass: "card-body nopadgrid card-body-180" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.impr.data, function (item, index) {
                    return _c(
                      "div",
                      { key: index, class: index == 0 ? "col-12" : "col-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "man-card-cnt2 text-white",
                            class: _vm.bgColorItem[index],
                          },
                          [
                            _vm._v(" " + _vm._s(item.ibmStepName) + " "),
                            _c("div", { staticClass: "man-card-cnt-man2" }, [
                              _vm._v(_vm._s(item.cnt + "건")),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("div", { staticClass: "cardcontents" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _vm._v(" 위험성평가 추세 변화 "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-body nopadgrid card-body-min200" },
                        [
                          _c("apexchart", {
                            ref: "riskLevelChart",
                            attrs: {
                              height: "200",
                              type: "bar",
                              options: _vm.riskLevelChange.chartOptions,
                              series: _vm.riskLevelChange.series,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    staticStyle: { "padding-right": "0px !important" },
                  },
                  [
                    _c("div", { staticClass: "cardcontents" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _vm._v(" 부적합/개선조치 추세 변화 "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-body nopadgrid card-body-min200" },
                        [
                          _c("apexchart", {
                            ref: "imprLevelChart",
                            attrs: {
                              height: "200",
                              type: "bar",
                              options: _vm.imprLevelChange.chartOptions,
                              series: _vm.imprLevelChange.series,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("div", { staticClass: "cardcontents" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _vm._v(" 잔존 위험요인 리스트 "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-body nopadgrid card-body-min200" },
                        [
                          _c("c-table", {
                            ref: "table",
                            attrs: {
                              columns: _vm.grid.columns,
                              data: _vm.grid.data,
                              isTitle: false,
                              isDashboard: true,
                              columnSetting: false,
                              usePaging: false,
                              filtering: false,
                              isFullScreen: false,
                              hideBottom: true,
                              merge: _vm.grid.merge,
                              gridHeight: _vm.grid.height,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "customArea",
                                fn: function ({ props, col }) {
                                  return [
                                    col.name === "customCol" &&
                                    props.row["sopImprovementIds"] &&
                                    props.row["ibmStepNames"]
                                      ? _vm._l(
                                          _vm.$comm.ibmTagItems2(props.row),
                                          function (item, index) {
                                            return _c(
                                              "q-chip",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  "margin-bottom":
                                                    "4px !important",
                                                },
                                                attrs: {
                                                  color: item.color,
                                                  clickable: true,
                                                  "text-color": "white",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.linkClick(
                                                      item,
                                                      props.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.title) + " "
                                                ),
                                                _c("c-assess-tooltip", {
                                                  attrs: {
                                                    ibmTooltip: item.ibmTooltip,
                                                    ibmClassCd: item.ibmClassCd,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      : col.name === "afterRisk"
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "description-td" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticClass: "text-negative",
                                                  staticStyle: {
                                                    "font-size": "1.4em",
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row["afterRiskName"]
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
            [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" 안전작업 위치 "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-more main-header-input main-header-input-date",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: { default: "today", name: "workPermitDate" },
                        on: { datachange: _vm.getWorkPermit },
                        model: {
                          value: _vm.workPermit.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.workPermit, "date", $$v)
                          },
                          expression: "workPermit.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "card-more card-more-txt",
                      on: { click: _vm.goPageMap },
                    },
                    [_vm._v("MAP보기")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.workPermit.maps && _vm.workPermit.maps.length > 0
                      ? _c(
                          "q-carousel",
                          {
                            staticClass: "workPermit-carousel",
                            attrs: {
                              swipeable: "",
                              animated: "",
                              arrows: "",
                              "control-type": "regular",
                              "control-color": "orange",
                              infinite: "",
                            },
                            model: {
                              value: _vm.workPermit.slide,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "slide", $$v)
                              },
                              expression: "workPermit.slide",
                            },
                          },
                          _vm._l(_vm.workPermit.maps, function (map, idx) {
                            return _c(
                              "q-carousel-slide",
                              {
                                key: idx,
                                attrs: { name: idx, "img-src": map.mapSrc },
                              },
                              [
                                _vm._l(map.maps, function (item, idx) {
                                  return _c(
                                    "VueDraggableResizable",
                                    {
                                      key: idx,
                                      ref: "markImage",
                                      refInFor: true,
                                      staticClass: "mainMarkImage",
                                      attrs: {
                                        resizable: false,
                                        parent: true,
                                        draggable: false,
                                        x: item.locationXcoordinate,
                                        y: item.locationYcoordinate,
                                        w: 30,
                                        h: 20,
                                        grid: [30, 20],
                                      },
                                    },
                                    [
                                      _c("q-icon", {
                                        staticClass: "blinking",
                                        attrs: { name: "push_pin" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "markImage-abbreviation-parent",
                                          style: {
                                            width: !item.isHover
                                              ? item.resultTypes.length > 3
                                                ? "227px"
                                                : _vm.getWidth(item)
                                              : "241px",
                                            left: _vm.getLeft(item),
                                            top: _vm.getTop(item),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "markImage-abbreviation",
                                              on: {
                                                mouseover: () => {
                                                  item.isHover = true
                                                },
                                                mouseleave: () => {
                                                  item.isHover = false
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "transition",
                                                {
                                                  attrs: {
                                                    name: "mark-list",
                                                    tag: "div",
                                                  },
                                                },
                                                [
                                                  !item.isHover
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "markImage-tran",
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.resultTypes.slice(
                                                              0,
                                                              3
                                                            ),
                                                            function (
                                                              resultType,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "q-banner",
                                                                {
                                                                  key: idx,
                                                                  class: [
                                                                    "markImage-abbreviation-banner",
                                                                    item
                                                                      .resultTypes
                                                                      .length <
                                                                      4 &&
                                                                    idx ===
                                                                      item
                                                                        .resultTypes
                                                                        .length -
                                                                        1
                                                                      ? "last-banner"
                                                                      : "",
                                                                  ],
                                                                  attrs: {
                                                                    dense: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c("q-icon", {
                                                                    staticClass:
                                                                      "text-red",
                                                                    attrs: {
                                                                      name: _vm.workPermitIconName(
                                                                        resultType
                                                                      ),
                                                                    },
                                                                  }),
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          resultType.sopWorkTypeName
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          item.resultTypes
                                                            .length > 3
                                                            ? _c(
                                                                "q-banner",
                                                                {
                                                                  key: "-1",
                                                                  class: [
                                                                    "markImage-abbreviation-plus",
                                                                  ],
                                                                  attrs: {
                                                                    dense: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c("q-icon", {
                                                                    staticClass:
                                                                      "text-red",
                                                                    attrs: {
                                                                      name: "add",
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .resultTypes
                                                                        .length -
                                                                        3
                                                                    ) + " "
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "q-icon",
                                                                        {
                                                                          staticClass:
                                                                            "text-black",
                                                                          attrs:
                                                                            {
                                                                              name: "more_horiz",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "transition",
                                                {
                                                  attrs: {
                                                    name: "mark-list",
                                                    tag: "div",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: item.isHover,
                                                          expression:
                                                            "item.isHover",
                                                        },
                                                      ],
                                                    },
                                                    _vm._l(
                                                      item.resultTypes,
                                                      function (
                                                        resultType,
                                                        idx
                                                      ) {
                                                        return _c(
                                                          "q-banner",
                                                          {
                                                            key: idx,
                                                            class: [
                                                              idx % 2 === 1
                                                                ? "bg-grey-3"
                                                                : "bg-grey-1",
                                                              "markImage-abbreviation-banner-detail",
                                                            ],
                                                            attrs: {
                                                              dense: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "avatar",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "q-icon",
                                                                        {
                                                                          staticClass:
                                                                            "text-red",
                                                                          attrs:
                                                                            {
                                                                              name: _vm.workPermitIconName(
                                                                                resultType
                                                                              ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  resultType.sopWorkTypeName
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("q-badge", {
                                                              attrs: {
                                                                color:
                                                                  _vm.workPermitbadgeColor(
                                                                    resultType.swpStepName
                                                                  ),
                                                                label:
                                                                  resultType.swpStepName,
                                                              },
                                                            }),
                                                            _c("q-btn", {
                                                              staticClass:
                                                                "q-ml-sm",
                                                              attrs: {
                                                                outline: "",
                                                                round: "",
                                                                color: "red",
                                                                size: "9px",
                                                                label: "",
                                                                icon: "add",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openWorkPermit(
                                                                      resultType
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "caption-work",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      resultType.workSummary
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          1
                        )
                      : _c("el-empty", {
                          attrs: {
                            "image-size": 435,
                            description: "지도에 등록된 작업허가서가 없습니다.",
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "q-dialog",
            {
              attrs: { position: "top" },
              model: {
                value: _vm.noticedialog,
                callback: function ($$v) {
                  _vm.noticedialog = $$v
                },
                expression: "noticedialog",
              },
            },
            [
              _c(
                "q-card",
                { staticStyle: { "min-width": "800px", width: "800px" } },
                [
                  _c(
                    "q-card-section",
                    { staticClass: "row items-center no-wrap mainpoplayer" },
                    [
                      _c(
                        "q-list",
                        {
                          staticClass: "rounded-borders",
                          staticStyle: { width: "100%" },
                          attrs: { bordered: "" },
                        },
                        _vm._l(_vm.popNotices, function (tag) {
                          return _c(
                            "q-expansion-item",
                            {
                              key: tag.sysNoticeId,
                              staticStyle: {
                                "border-bottom": "1px solid #fff",
                              },
                              attrs: {
                                group: "noticepops",
                                "header-class": "bg-orange-7 text-white",
                                "expand-icon-class": "text-white",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "q-item-section",
                                          { attrs: { avatar: "" } },
                                          [
                                            _c("q-icon", {
                                              attrs: {
                                                color: "white",
                                                name: "mail",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("q-item-section", [
                                          _vm._v(
                                            " " + _vm._s(tag.noticeTitle) + " "
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: tag.expanded,
                                callback: function ($$v) {
                                  _vm.$set(tag, "expanded", $$v)
                                },
                                expression: "tag.expanded",
                              },
                            },
                            [
                              _c(
                                "q-card",
                                [
                                  _c("q-card-section", [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(tag.noticeContents),
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "q-card",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: tag.dayViewDisable,
                                      expression: "tag.dayViewDisable",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "q-card-section",
                                    [
                                      _c("q-toggle", {
                                        staticClass: "anymoreNotice",
                                        attrs: {
                                          size: "sm",
                                          "checked-icon": "check",
                                          color: "red",
                                          label: "더이상 보지 않기",
                                          "unchecked-icon": "clear",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.updateTodayChk(
                                              tag.sysNoticeId
                                            )
                                          },
                                        },
                                        model: {
                                          value: tag.dayView,
                                          callback: function ($$v) {
                                            _vm.$set(tag, "dayView", $$v)
                                          },
                                          expression: "tag.dayView",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }