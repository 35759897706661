<template>
  <div class="dashboard">
    <!-- <div class="row topmainrow">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="topmainlayer card">
          <div class="nowdate">{{nowdatestring}} <span class="nowplant"><span class="material-icons plantTitle">villa</span>{{nowplant}}</span></div>
          <div class="title">(건설용-SaaS) <span class="title1">안전보건관리체계 솔루션</span> <span class="title2">Andami </span> <span class="title3">Safety-Doumi</span></div>
        </div>
      </div>
    </div> -->
    <div class="row mainchild">
      <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="cardcontents">
          <div class="card-header">
            공종별 실적진행율
          </div>
          <div class="card-body nopadgrid card-body-min200">
            <table style="width:100%">
              <tbody>
                <tr v-for="(item, idx) in process.data"
                  :key="idx">
                  <td class="text-left" style="padding-bottom:2px;">
                    <div style="min-width: 100% !important">{{ item.processName }}</div>
                  </td>
                  <td class="text-center mainGridTableTd" style="width: 60%;padding-left: 10px;padding-top:10px;10px;padding-bottom:2px;">
                    <div class="widget-content p-0">
                      <div class="widget-content-outer">
                        <div class="widget-content-wrapper">
                          <div class="widget-content-left pr-2">
                            <div class="widget-numbers fsize-1 text-dark">{{item.resultPercent}}%</div>
                          </div>
                          <div class="widget-content-right w-100">
                            <div class="progress-bar-xs progress">
                              <div class="progress-bar" :class="item.resultPercent > 50 ? 'bg-success' : 'bg-orange'" role="progressbar" :aria-valuenow="item.resultPercent"
                                aria-valuemin="0" aria-valuemax="100" :style="'width: '+item.resultPercent+'%;'">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="cardcontents">
          <div class="card-header">
            금일 TBM
            <div class="card-more card-more-txt" @click="goPage('/sai/tbm/tbm')">상세보기</div>
          </div>
          <div class="card-body nopadgrid card-body-min200">
            <c-table
              ref="tbm"
              tableId="tbm"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="170px"
              :columns="tbm.columns"
              :data="tbm.data"
              @rowClick="clickTbm"
            >
            </c-table>
          </div>
        </div>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <div class="cardcontents">
          <div class="card-header">
            메뉴 이동하기
          </div>
          <div class="card-body nopadgrid card-body-180">
            <div class="row">
              <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div class="maincard card" @click="goPage('/mdm/pjm/projectManage')">
                  <q-img
                    :src="require('@/assets/images/accident2.jpg')"
                    class="image-thumbnail"
                    style="height: 145px; max-width: 180px; border-radius: 15px"
                  > 
                    <div class="d-flex align-items-center" style="width:100%;height:100%">
                      <div class="flex-grow-1">
                        <div class="text-xs fw-bold text-white mb-1 h5">
                          <span class="material-icons">accessible</span>
                          공사현장</div>
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.nearAccCnt + '건' }}</span> -->
                      </div>
                    </div>
                  </q-img>
                </div>
              </div>
              <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div class="maincard card" @click="goPage('/saas/riskAssessList')">
                  <q-img
                    :src="require('@/assets/images/improve2.jpg')"
                    class="image-thumbnail"
                    style="height: 145px; max-width: 180px; border-radius: 15px"
                  > 
                    <div class="d-flex align-items-center" style="width:100%;height:100%">
                      <div class="flex-grow-1">
                        <div class="text-xs fw-bold text-white mb-1 h5">
                          <span class="material-icons">warning</span>
                          위험성평가</div>
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.riskAssessCnt + '건' }}</span> -->
                        <span :class="['txt-box-grid text-red-box', 'text-position']">{{ '3건' }}</span>
                      </div>
                    </div>
                  </q-img>
                </div>
              </div>
              <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div class="maincard card" @click="goPage('/sop/swp/safeWorkPermit')">
                  <q-img
                    :src="require('@/assets/images/work2.jpg')"
                    class="image-thumbnail"
                    style="height: 145px; max-width: 180px; border-radius: 15px"
                  > 
                    <div class="d-flex align-items-center" style="width:100%;height:100%">
                      <div class="flex-grow-1">
                        <div class="text-xs fw-bold text-white mb-1 h5">
                          <span class="material-icons">handyman</span>
                          작업허가</div>
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ '금일: ' + data.workPermitCnt +'건' }}</span> -->
                        <span :class="['txt-box-grid text-red-box', 'text-position']">{{ '금일: ' + '5건' }}</span>
                      </div>
                    </div>
                  </q-img>
                </div>
              </div>
              <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div class="maincard card" @click="goPage('/sai/tbm/tbm')">
                  <q-img
                    :src="require('@/assets/images/tbm2.jpg')"
                    class="image-thumbnail"
                    style="height: 145px; max-width: 180px; border-radius: 15px"
                  > 
                    <div class="d-flex align-items-center" style="width:100%;height:100%">
                      <div class="flex-grow-1">
                        <div class="text-xs fw-bold text-white mb-1 h5">
                          <span class="material-icons">engineering</span>
                          TBM</div>
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ '금일: ' + data.tbmCnt +'건' }}</span> -->
                        <span :class="['txt-box-grid text-red-box', 'text-position']">{{ '금일: ' + '3건' }}</span>
                      </div>
                    </div>
                  </q-img>
                </div>
              </div>
              <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <div class="maincard card" @click="goPage('/sai/safetycheck/safetyCheck')">
                  <q-img
                    :src="require('@/assets/images/safetycheck2.jpg')"
                    class="image-thumbnail"
                    style="height: 145px; max-width: 180px; border-radius: 15px"
                  > 
                    <div class="d-flex align-items-center" style="width:100%;height:100%">
                      <div class="flex-grow-1">
                        <div class="text-xs fw-bold text-white mb-1 h5">
                          <span class="material-icons">query_stats</span>
                          안전순찰</div>
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                        <span :class="['txt-box-grid text-red-box', 'text-position']">{{ Number(data.safetyFlag) === 0 ? '금일: 점검필요' : '금일: 점검완료'  }}</span>
                      </div>
                    </div>
                  </q-img>
                </div>
              </div>
              <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <div class="maincard card" @click="goPage('/sop/iim/near/nearAccident')">
                  <q-img
                    :src="require('@/assets/images/education2.jpg')"
                    class="image-thumbnail"
                    style="height: 145px; max-width: 180px; border-radius: 15px"
                  > 
                    <div class="d-flex align-items-center" style="width:100%;height:100%">
                      <div class="flex-grow-1">
                        <div class="text-xs fw-bold text-white mb-1 h5">
                          <span class="material-icons">accessible</span>
                          아차사고</div>
                        <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.eduCompleteCnt + '/' + data.eduAllCnt + '건' }}</span> -->
                      </div>
                    </div>
                  </q-img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
        <div class="cardcontents">
          <div class="card-header">
            업체별 투입인력
          </div>
          <div class="card-body nopadgrid card-body-180">
            <div class="row">
              <div :class="index==0 ? 'col-12' : 'col-12'" v-for="(item, index) in vendorInfo.vendors" :key="index">
                <div class="man-card-cnt2 text-white" :class="bgColorItem[index]">
                  {{ item.vendorName }} <div class="man-card-cnt-man2">{{ item.vendorCount + "명" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
        <div class="cardcontents">
          <div class="card-header">
            부적합 개선현황
            <div class="card-more card-more-txt" @click="goPage('/sop/ibm/impr')">상세보기</div>
          </div>
          <div class="card-body nopadgrid card-body-180">
            <div class="row">
              <div :class="index==0 ? 'col-12' : 'col-12'" v-for="(item, index) in impr.data" :key="index">
                <div class="man-card-cnt2 text-white" :class="bgColorItem[index]">
                  {{ item.ibmStepName }} <div class="man-card-cnt-man2">{{ item.cnt + "건" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="cardcontents">
              <div class="card-header">
                위험성평가 추세 변화
              </div>
              <div class="card-body nopadgrid card-body-min200">
                <apexchart
                  ref="riskLevelChart"
                  height="200"
                  type="bar"
                  :options="riskLevelChange.chartOptions"
                  :series="riskLevelChange.series"
                >
                </apexchart>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-right:0px !important">
            <div class="cardcontents">
              <div class="card-header">
                부적합/개선조치 추세 변화
              </div>
              <div class="card-body nopadgrid card-body-min200">
                <apexchart
                  ref="imprLevelChart"
                  height="200"
                  type="bar"
                  :options="imprLevelChange.chartOptions"
                  :series="imprLevelChange.series"
                >
                </apexchart>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cardcontents">
              <div class="card-header">
                잔존 위험요인 리스트
              </div>
              <div class="card-body nopadgrid card-body-min200">
                <c-table
                  ref="table"
                  :columns="grid.columns"
                  :data="grid.data"
                  :isTitle="false"
                  :isDashboard="true"
                  :columnSetting="false"
                  :usePaging="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :hideBottom="true"
                  :merge="grid.merge"
                  :gridHeight="grid.height"
                >
                  <template v-slot:customArea="{ props, col }">
                    <template v-if="col.name==='customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
                      <q-chip
                        v-for="(item, index) in $comm.ibmTagItems2(props.row)"
                        :key="index"
                        :color="item.color"
                        :clickable="true"
                        text-color="white"
                        style="margin-bottom:4px !important;"
                        @click="linkClick(item, props.row)">
                        {{item.title}}
                        <c-assess-tooltip 
                          :ibmTooltip="item.ibmTooltip"
                          :ibmClassCd="item.ibmClassCd"
                        />
                      </q-chip>
                    </template>
                    <template v-else-if="col.name==='afterRisk'">
                      <div class="description-td">
                        <font class="text-negative" style="font-size:1.4em;font-weight:500;">{{props.row['afterRiskName']}}</font>
                      </div>
                    </template>
                  </template>
                </c-table>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="cardcontents">
          <div class="card-header">
            안전작업 위치
            <!-- <div class="card-more card-more-txt" @click="goPage('/sop/swp/safeWorkPermit')">상세보기</div> -->
            <div class="card-more main-header-input main-header-input-date">
              <c-datepicker
                default="today"
                name="workPermitDate"
                v-model="workPermit.date"
                @datachange="getWorkPermit"
              />
            </div>
            <div class="card-more card-more-txt" @click="goPageMap">MAP보기</div>
          </div>
          <div class="card-body">
            <q-carousel
              v-if="workPermit.maps && workPermit.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="orange"
              v-model="workPermit.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in workPermit.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <VueDraggableResizable
                    v-for="(item, idx) in map.maps"
                    :key="idx"
                    ref="markImage"
                    class="mainMarkImage"
                    :resizable="false"
                    :parent="true"
                    :draggable="false"
                    :x="item.locationXcoordinate"
                    :y="item.locationYcoordinate"
                    :w="30" :h="20"
                    :grid="[30, 20]"
                  >
                    <!-- {{idx + 1}} -->
                    <q-icon name="push_pin" class="blinking" />
                    <div class="markImage-abbreviation-parent"
                      :style="{ 
                        width: !item.isHover 
                          ? item.resultTypes.length > 3 ? '227px' : getWidth(item)
                          : '241px', 
                        left: getLeft(item),
                        top: getTop(item),
                      }">
                      <div class="markImage-abbreviation" 
                        @mouseover="() => { item.isHover = true }"
                        @mouseleave="() => { item.isHover = false }">
                        <transition name="mark-list" tag="div">
                          <div v-if="!item.isHover" class="markImage-tran">
                            <q-banner v-for="(resultType, idx) in item.resultTypes.slice(0, 3)"
                              :key="idx"
                              dense 
                              :class="[
                                'markImage-abbreviation-banner',
                                item.resultTypes.length < 4 && idx === item.resultTypes.length - 1 ? 'last-banner' : ''
                              ]">
                              <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                              <div>
                                {{resultType.sopWorkTypeName}}
                              </div>
                            </q-banner>
                            <q-banner v-if="item.resultTypes.length > 3"
                              key="-1"
                              dense 
                              :class="['markImage-abbreviation-plus']">
                              <q-icon name="add" class="text-red" />{{item.resultTypes.length - 3}}
                              <div>
                                <q-icon name="more_horiz" class="text-black" />
                              </div>
                            </q-banner>
                          </div>
                        </transition>
                        <transition name="mark-list" tag="div">
                          <div v-show="item.isHover">
                            <q-banner v-for="(resultType, idx) in item.resultTypes"
                              :key="idx"
                              dense 
                              :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                              <template v-slot:avatar>
                                <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                              </template>
                              {{resultType.sopWorkTypeName}}
                              <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                              <q-btn class="q-ml-sm" outline round color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                              <div class="caption-work">
                                {{resultType.workSummary}}
                              </div>
                            </q-banner>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </VueDraggableResizable>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="지도에 등록된 작업허가서가 없습니다."></el-empty>
          </div>
        </div>
      </div>
      <q-dialog v-model="noticedialog" position="top">
        <q-card style="min-width: 800px;width: 800px">
          <q-card-section class="row items-center no-wrap mainpoplayer">
            <q-list bordered class="rounded-borders" style="width: 100%">
              <q-expansion-item v-for="tag in popNotices"
                :key="tag.sysNoticeId"
                group="noticepops"
                header-class="bg-orange-7 text-white"
                style="border-bottom: 1px solid #fff;"
                expand-icon-class="text-white"
                v-model="tag.expanded">
                <template v-slot:header>
                  <q-item-section avatar>
                    <q-icon color="white" name="mail" />
                  </q-item-section>
                  <q-item-section>
                    {{tag.noticeTitle}}
                  </q-item-section>
                </template>
                <q-card>
                  <q-card-section>
                    <div v-html="tag.noticeContents"/>
                  </q-card-section>
                </q-card>
                <q-card v-show="tag.dayViewDisable">
                  <q-card-section>
                    <q-toggle
                      size="sm"
                      class="anymoreNotice"
                      v-model="tag.dayView"
                      checked-icon="check"
                      color="red"
                      label="더이상 보지 않기"
                      unchecked-icon="clear"
                      @input="updateTodayChk(tag.sysNoticeId)"
                    />
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </q-list>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'main-dashboard',
  components: { VueDraggableResizable, apexchart: VueApexCharts, },
  props: {
  },
  data() {
    return {
      nowdatestring: '',
      nowplant: '',
      noticedialog: false,
      impr: {
        listUrl: '',
        data: {},
      },
      risk: {
        listUrl: '',
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'improveCount',
            field: 'improveCount',
            label: '조치완료',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          { 
            name: 'resImproveCount',
            field: 'resImproveCount',
            label: '잔여부적합',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: {},
      },
      bgColorItem: ['bg-orange','bg-light-green','bg-light-blue','bg-deep-purple-6','bg-pink-6','bg-indigo-6','bg-yellow-9'],
      workPermit: {
        slide: 0,
        date: '', 
        listUrl: '',
        todayListUrl: '',
        mapSrc: require('@/assets/images/no-image.png'),
        maps: [],
      },
      statementItemUse: {
        listUrl: '',
        plant: {
          plantCd: null,
          plantName: null,
          projectPeriod: null,
          projectSafetyCost: 0,
          stackAmount: 0,
          projectAddress: '',
          projectCost: 0,
          companyCd: '',
          statementItemUse: [],
        },
        fills: [
          { gradient: ['#d92550', '#f06887'] },
          { gradient: ['#3ac47d', '#82cca6'] },
          { gradient: ['#2af598', '#009efd'] },
          { gradient: ['#f7b924', '#f7d78e'] },
        ],
      },
      vendorInfo: {
        listUrl: '',
        vendors: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      noticePopUrl: '',
      popNotices: [],
      process: {
        data: []
      },
      grid: {
        columns: [
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해·위험요인<br/>(위험한 상황과 사건)',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          // {
          //   name: 'currentSafetyMeasures',
          //   field: 'currentSafetyMeasures',
          //   label: '현재안전조치',
          //   align: 'left',
          //   style: 'width:170px',
          //   sortable: false,
          // },
          {
            name: 'afterRisk',
            field: 'afterRisk',
            label: '판단결과',
            align: 'center',
            style: 'width:90px',
            type: 'custom',
            sortable: false,
          },
          // {
          //   name: 'improvementMeasures',
          //   field: 'improvementMeasures',
          //   label: '추가 리스크관리 계획',
          //   align: 'left',
          //   style: 'width:170px',
          //   sortable: false,
          // },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:160px',
            type: 'custom',
            sortable: false
          },
        ],
        merge: [
          { index: 0, colName: 'assessVendorId' },
          { index: 1, colName: 'processName' },
        ],
        data: [],
        height: '270px'
      },
      riskLevelChange: {
        chartOptions: {
          title: {
            text: '위험성평가 추세 변화'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return  val
              }
            }
          },
          yaxis: {
            title: {
              text: '판단결과',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '상',
            data: [],
          },
          {
            name: '중',
            data: [],
          },
          {
            name: '하',
            data: [],
          },
        ],
      },
      imprLevelChange: {
        chartOptions: {
          title: {
            text: '부적합/개선 추세 변화'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function (val) {
                return  val
              }
            }
          },
          yaxis: {
            title: {
              text: '판단결과/부적합',
              style: {
                fontSize: '14px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          fill: {
            opacity: 1,
          },
        },
        series: [
          {
            name: '진행 건 수',
            data: [],
          },
          {
            name: '완료 건 수',
            data: [],
          },
          {
            name: '지연 건 수',
            data: [],
          },
        ],
      },
      noticeGrid: {
        listUrl: '',
        columns: [
          {
            name: 'noticeClassName',
            field: 'noticeClassName',
            label: '공지분류',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'noticeTitle',
            field: 'noticeTitle',
            label: '제목',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      grid1: {
        listUrl: '',
        columns: [
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   label: '공종',
          //   align: 'center',
          //   style: 'width: 100px',
          //   sortable: true,
          // },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            // style: 'width: 130px',
            sortable: true,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      tbm: {
        listUrl: '',
        columns: [
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   label: '공종',
          //   align: 'center',
          //   style: 'width: 100px',
          //   sortable: true,
          // },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            // style: 'width: 130px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        assessmentYears: [],
        afterRisk: null,
        processCd: null,
        assessVendorId: null,
      },
      vendorCd: '',
      startdt : '',
      enddt: '',
      data: {
        workPermitCnt: '',
        eduCnt: '',
        patrolCnt: '',
        nearAccCnt: '',
        imprVendorAllCnt: 0,
        imprVendorCompleteCnt: 0,
        imprPlantCompleteCnt: 0,
        imprPlantAllCnt: 0,
        imprCompanyAllCnt: 0,
        imprCompanyCompleteCnt: 0,
        imprList: [],
        dailyList: [],
        deteriorList: [],
        noticeList: [],
        mapList: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // url setting
      // this.impr.listUrl = selectConfig.main.impr.url
      this.listUrl = selectConfig.main.list.url;
      this.impr.listUrl = selectConfig.main.imprs.url
      this.workPermit.listUrl = selectConfig.main.workPermit.url
      this.workPermit.todayListUrl = selectConfig.main.workPermitToday.url
      this.statementItemUse.listUrl = selectConfig.smf.statementItemUse.list.url
      this.vendorInfo.listUrl = selectConfig.main.vendorInfo.url
      this.noticeGrid.listUrl = selectConfig.sys.notice.list.url;
      this.noticePopUrl = selectConfig.sys.notice.main.url;
      this.tbm.listUrl = selectConfig.main.tbmToday.url;

      this.riskChangeUrl = selectConfig.saas.status.riskLevel.url;
      this.imprChangeUrl = selectConfig.saas.status.imprLevel.url;
      this.residualRiskUrl = selectConfig.saas.status.residual.url 

      this.startdt = this.$comm.moment().add(-7, 'd').format('YYYY-MM-DD')
      this.enddt = this.$comm.moment().add(0, 'd').format('YYYY-MM-DD')
      
      // this.searchParam.assessmentYears = [String(Number(this.$comm.getThisYear()) - 3), this.$comm.getThisYear()]
      this.nowdatestring = this.$comm.getTodayHan();
      this.nowplant = this.$store.getters.user.companyName;
      // this.workPermit.date = this.$comm.getToday();
      // this.workPermit.date = '2023-05-08';

      if (this.$store.getters.user.innerFlag === 'N') {
        this.vendorCd = this.$store.getters.user.deptCd;
      } 
      
      // list setting
      if (this.$store.getters.user.plantCd && window.sessionStorage.getItem('defaultPlantCd') !== 'null') {
        this.getProcess();
        this.getImpr();
        this.getRiskAssess();
        this.getWorkPermit();
        this.getRecentWorkPermits();
        this.getStatementItemUse();
        this.getVendorInfo();
        this.getNoticePop();
        this.getTmbs();
        // let name = this.searchParam.afterRisk === 'RRT0000001' ? '하' : this.searchParam.afterRisk === 'RRT0000005' ? '중' : '상'
        // this.$set(this.imprLevelChange.series[0], 'name', '판단결과 ' + name + ' 건수')
        // this.riskChange();
        // this.imprChange();
        // this.residualRiskList();
        this.getList();
        this.getList2();
      }
    },
    getList2() {
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        plantCd: defaultPlantCd ? defaultPlantCd : this.$store.getters.user.plantCd,
        companyCd: this.$store.getters.user.companyCd,
        deptCd: this.$store.getters.user.innerFlag === 'N' ? this.$store.getters.user.deptCd : this.$store.getters.user.selfVendorCd,
        startDate: this.startDate,
        endDate: this.endDate,
        month: this.$comm.getThisMonth(),
        day: this.$comm.getToday(),
      };
      this.$http.request((_result) => {
        this.data = _result.data

        if (Number(this.data.imprVendorCompleteCnt) > 0 && Number(this.data.imprVendorAllCnt) > 0) {
          this.imprVendorPercent = (Number(this.data.imprVendorCompleteCnt) / Number(this.data.imprVendorAllCnt) * 100).toFixed()
        } else {
          this.imprVendorPercent = 0
        }
        if (Number(this.data.imprPlantCompleteCnt) > 0 && Number(this.data.imprPlantAllCnt) > 0) {
          this.imprPlantPercent = (Number(this.data.imprPlantCompleteCnt) / Number(this.data.imprPlantAllCnt) * 100).toFixed()
        } else {
          this.imprPlantPercent = 0
        }
        if (Number(this.data.imprCompanyCompleteCnt) > 0 && Number(this.data.imprCompanyAllCnt) > 0) {
          this.imprCompanyPercent = (Number(this.data.imprCompanyCompleteCnt) / Number(this.data.imprCompanyAllCnt) * 100).toFixed()
        } else {
          this.imprCompanyPercent = 0
        }

        console.log(_result.data)
        //  this.chartOptions.plotOptions.total.formatter.
        this.companySeries = [this.imprCompanyPercent]
        this.plantSeries = [this.imprPlantPercent]
        this.vendorSeries = [this.imprVendorPercent]
        // this.series = [this.imprCompanyPercent, this.imprPlantPercent, this.imprVendorPercent]
      },);
    },
    getList() {
      let name = this.searchParam.afterRisk === 'RRT0000001' ? '하' : this.searchParam.afterRisk === 'RRT0000005' ? '중' : '상'
      this.$set(this.imprLevelChange.series[0], 'name', '판단결과 [' + name + '] 건수')
      this.riskChange();
      this.imprChange();
      this.residualRiskList();
    },
    // yearchange() {
    //   if (!this.searchParam.assessmentYears || this.searchParam.assessmentYears.length === 0) {
    //     window.getApp.$emit("ALERT", {
    //       title: "안내", // 안내
    //       message: "해당년도를 선택하세요.", // 해당년도를 선택하세요.
    //       type: "warning", // success / info / warning / error
    //     });
    //     return;
    //   }
    //   let startYear = Number(this.searchParam.assessmentYears[0]);
    //   let lastYear = Number(this.searchParam.assessmentYears[1]);

    //   this.riskLevelChange.chartOptions.xaxis.categories = [];
    //   this.imprLevelChange.chartOptions.labels = [];

    //   for (let year = startYear; year <= lastYear; year++) {
    //     if (year === startYear) {
    //       this.riskLevelChange.chartOptions.xaxis.categories.push(year);
    //       this.imprLevelChange.chartOptions.labels.push(year);
    //     } else if (year === lastYear) {
    //       this.riskLevelChange.chartOptions.xaxis.categories.push(year);
    //       this.imprLevelChange.chartOptions.labels.push(year);
    //     } else {
    //       this.riskLevelChange.chartOptions.xaxis.categories.push(year);
    //       this.imprLevelChange.chartOptions.labels.push(year);
    //     }
    //   }
    //   this.getList();
    // },
    getProcess() {
      if (window.sessionStorage.getItem('defaultPlantCd')) {
        this.$http.url = this.$format(selectConfig.pjm.process.list.url, window.sessionStorage.getItem('defaultPlantCd'));
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.process.data = _result.data;
        },);
      }
    },
    getRiskAssess() {
      this.$http.url = selectConfig.saas.assessPlan.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        assessmentDates: [this.startdt, this.enddt],
      }
      this.$http.request((_result) => {
        this.risk.data = _result.data;
      },);
    },
    getTmbs() {
      this.$http.url = this.tbm.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        date: this.$comm.getToday(),
        vendorCd: this.vendorCd,
      }
      this.$http.request((_result) => {
        this.tbm.data = _result.data;
      },);
    },
    getImpr() {
      this.$http.url = this.impr.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
      }
      this.$http.request((_result) => {
        this.impr.data = _result.data;
      },);
    },
    getVendorInfo() {
      this.$http.url = this.vendorInfo.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
      }
      this.$http.request((_result) => {
        this.vendorInfo.vendors = _result.data;
      },);
    },
    getRecentWorkPermits() {
      this.$http.url = this.workPermit.todayListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        date: this.$comm.getToday(),
        vendorCd: this.vendorCd,
      }
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    getWorkPermit() {
      this.$http.url = this.workPermit.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        date: this.workPermit.date
      }
      this.$http.request((_result) => {
        this.workPermit.maps = _result.data;
        this.$_.forEach(this.workPermit.maps, map => {
          this.$_.forEach(map.maps, item => {
            this.$set(item, 'isHover', false)
          });
        })
      },);
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    getStatementItemUse() {
      this.$http.url = this.statementItemUse.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        month: this.$comm.getThisMonth(),
        companyCd: this.$store.getters.user.companyCd,
      }
      this.$http.request((_result) => {
        this.$_.extend(this.statementItemUse.plant, _result.data)
      },);
    },
    getNotice() {
      this.$http.url = this.noticeGrid.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pagelimit: 5,
        companyCd: this.$store.getters.user.companyCd,
      }
      this.$http.request((_result) => {
        this.noticeGrid.data = _result.data;
      },);
    },
    getNoticePop() {
      this.popNotices = [];
      this.$http.url = this.noticePopUrl;
      this.$http.type = 'GET';
      this.$http.param = {}
      this.$http.request((_result) => {
        let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
        if (mainPopNoticeId !== null) {
          let icnt=0;
          this.$_.forEach(_result.data, item => {
            if (mainPopNoticeId.indexOf(item.sysNoticeId) < 0) {
              item.dayViewDisable = true;
              if (icnt == 0) {
                item.expanded = true;
              } else {
                item.expanded = false;
              }
              this.popNotices.push(item);
              icnt++;
            }
          })
          if (this.popNotices.length == _result.data.length) {
            window.localStorage.removeItem('mainPopNoticeId');
          }
        } else {
          this.popNotices = _result.data;
          let icnt=0;
          this.$_.forEach(this.popNotices, item => {
            item.dayViewDisable = true;
            if (icnt == 0) {
              item.expanded = true;
            } else {
              item.expanded = false;
            }
            icnt++;
          })
        }
        if (this.popNotices.length > 0) {
          this.noticedialog = true
        }
      },);
    },
    getNoticeDetail(_title, _content) {
      this.popNotices = [{
        sysNoticeId: '',
        expanded: true,
        dayView: false,
        noticeTitle: _title,
        noticeContents: _content,
        dayViewDisable: false,
      }];
      this.noticedialog = true;
    },
    updateTodayChk(_noticeId) {
      let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
      if (mainPopNoticeId === null) {
        window.localStorage.setItem('mainPopNoticeId', _noticeId + ',');
      } else {
        if (mainPopNoticeId.indexOf(_noticeId) < 0) {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId + _noticeId + ',');
        } else {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId.replace(_noticeId + ',',''));
        }
      }
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    rowClickNotice(row) {
      this.getNoticeDetail(row.noticeTitle, row.noticeContents);
    },
    setColor(vendorRate) {
      let color = 'progress-bar ';
      if (Number(vendorRate) === 100) {
        color += 'bg-primary'
      } else if (Number(vendorRate) >= 66) {
        color += 'bg-success'
      } else if (Number(vendorRate) >= 33 && Number(vendorRate) < 66) {
        color += 'bg-warning'
      } else {
        color += 'bg-danger'
      }
      return color;
    },
    clickSopSwp(row) {
      this.popupOptions.title = '작업허가서';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    clickTbm(row) {
      this.popupOptions.title = 'TBM 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.target = () => import(`${'@/pages/sai/tbm/tbmDetail.vue'}`);
      this.popupOptions.param = {
        tbmId: row ? row.tbmId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    clickRisk(row) {
      this.popupOptions.title = '위험성평가 상세'; // 위험성평가 상세
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        riskAssessmentPlanId: row ? row.riskAssessmentPlanId : '',
        stepCd: row ? row.stepCd : 'RAS000001',
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/riskAssessDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    goPageMap() {
      this.popupOptions.width = '60%';
      this.popupOptions.title = "작업허가 MAP";
      this.popupOptions.param = {
        companyCd: this.$store.getters.user.companyCd,
        date: this.workPermit.date
      };
      this.popupOptions.target = () => import(`${'./indexMap.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    riskChange() {
      this.$http.url = this.riskChangeUrl;
      this.$http.type = "GET";
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        date: this.workPermit.date
      }
      this.$http.request((_result) => {
        // series.data length 구하기
        this.$set(this.riskLevelChange.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'plantName'))
        let series = [
          {
            name: '상',
            data: this.$_.map(_result.data, 'highCount')
          },
          {
            name: '중',
            data: this.$_.map(_result.data, 'mediumCount')
          },
          {
            name: '하',
            data: this.$_.map(_result.data, 'lawCount')
          },
        ];
        this.$set(this.riskLevelChange, 'series', series)

        this.riskLevelChange.chartWidth = '100%';
        this.$refs["riskLevelChart"].refresh();
      });
    },
    imprChange() {
      this.$http.url = this.imprChangeUrl;
      this.$http.type = "GET";
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        date: this.workPermit.date
      }
      this.$http.request((_result) => {

        this.$set(this.imprLevelChange.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'plantName'))
        let series = [
          {
            name: '진행 건 수',
            data: this.$_.map(_result.data, 'cntIng'),
          },
          {
            name: '완료 건 수',
            data: this.$_.map(_result.data, 'cntComplete'),
          },
          {
            name: '지연 건 수',
            data: this.$_.map(_result.data, 'cntInaction'),
          }
        ];

        this.$set(this.imprLevelChange, 'series', series)
        console.log(this.imprLevelChange)

        this.imprLevelChange.chartWidth = '100%';
        this.$refs["imprLevelChart"].refresh();
      })
    },
    residualRiskList() {
      // 선택된 위험성평가 정보가 있는 경우
      this.$http.url = this.residualRiskUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.$store.getters.user.companyCd,
        date: this.workPermit.date
      }
      this.$http.request((_result) => {
        this.grid.data = this.$_.reject(_result.data, {afterRisk: null})
      })
    },
    
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.residualRiskList();
      }
    },
  }
};
</script>
<style lang="sass">
  .card-more-txt
    font-size:12px
    margin-top: 3px
    padding-right: 10px
  .dashboard .cardcontents
    box-shadow: 0 !important
    border: 1px solid rgba(0,0,0,0.12) !important
    border-radius: 10px !important
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
      border-radius: 0 0 10px 10px !important
      background: #fff
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      border-bottom-width: 0px !important
      border-radius: 10px 10px 0 0 !important
      height: 35px
      color: #0072c6
      background: #f8f8f9
      display: inline-block
      padding-top: 8px
      width: 100%
      .card-more
        float: right !important
        cursor: pointer
        color: #0394ff
  .mainchild
    padding: 0px 0px 0px 0px 
    .progress-bar-xs
      height: 10px !important
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    .card-body
      padding: 10px
    .card-body2
      padding: 0px !important
    .card-body3
      padding: 5px 10px 10px 10px !important
    // .card-header:before
    //   font-family: "Material Icons"
    //   content: "\e834"
    .card-header
      border-radius: 15px 15px 0 0
      padding-left: 15px !important
      padding-right: 4px !important
      font-size: 15px
      border-bottom-color: #fff !important
      height: 40px
      color: inherit
      background: #fff
      display: inline-block
      padding-top: 8px
      .card-more
        float: right !important
        cursor: pointer
        color: #0394ff
  .maincard .card-body
    background: #fff
    border-radius: 30px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #ddd
      cursor: pointer
  .maincard .card-body.border-color-blue
    border-top: 4px solid #0061f2
  .maincard .card-body.border-color-red
    border-top: 4px solid #d92550
  .maincard .card-body.border-color-yellow
    border-top: 4px solid #f7b924
  .maincard .card-body.border-color-green
    border-top: 4px solid #3ac47d
  .cardcontents2
    margin-top: 0px !important
.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  height: 30px !important
  position: static
  padding: 16px
  color: #C10015
  // background: rgba(0,0,0,0.47)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px

.mark-list-enter-active, .mark-list-leave-active
  transition: all 0.3s
.mark-list-enter, .mark-list-leave-to
  opacity: 0
  transform: translateY(5px)

.caption-work
  font-size: 0.85em
  font-style: italic
  white-space: normal
  color: #555
  margin-top: 4px

.workPermit-carousel
  height: 565px !important
  .q-carousel__slide
    width: 800px !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important

.main-header-input
  margin-top: -5px
  padding-right: 5px
  .fix-height
    width:120px
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
  .customDate.q-field--filled
    .q-field__control
      border: 0px solid rgba(0, 0, 0, 0.15) !important
      padding: 0px 8px 0px 2px
.mainpoplayer
  padding: 6px 6px 6px 6px !important
.anymoreNotice
  position: absolute
  bottom: 10px
  right: 20px
.app-main__outer .q-panel.scroll
  overflow: hidden !important
.mainCardSizeM
  height: 249px
.mainCardSizeM3
  height: 400px
.mainCardSizeM2
  height: 655px
.topMainTitle
  font-size: 17px
  font-weight: 600
  color: #555
  padding-bottom: 0px

.topMainArea
  display: inline-flex
.topMainGraph
  padding:10px 10px 0px 10px
.topMainContent
  padding:10px 10px 0px 10px
  .topMainTitle1
    font-size: 16px
    font-weight: 600
    color: #555
  .topMainTitle2
    font-size: 2.3em
    font-weight: 700
    color: #555
    padding: 1px 0px

.padd0
  padding-top:0px !important
.fsize-1
  width: 60px
.costplanTitle
  font-weight: 600
  padding-bottom: 25px
.main-header-input-date
  width: 150px !important

.dashboard .card
  border-radius: 15px !important
.dashboard .cdatetimepic .mx-input-wrapper
  padding: 0px 3px
  margin-bottom: 0px
  margin-top: 20px
  width: 140px
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.card-body-min160 {
  min-height: 160px;
}
.card-body-min170 {
  min-height: 170px;
}
.card-body-min180 {
  min-height: 180px;
}
.card-body-min190 {
  min-height: 190px;
}
.card-body-min200 {
  min-height: 200px;
}
.man-card-cnt {
  border-radius: 5px;
  width: 100%;
  height: 30px;
  line-height: 40px;
  padding: 0px 10px;
  display: inline-block;
}
.man-card-cnt-man {
  float: right;
  font-size: 0.9em;
}
.man-card-cnt2 {
  border-radius: 5px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  display: inline-block;
}
.man-card-cnt-man2 {
  float: right;
  font-size: 0.9em;
}

</style>

<style lang="sass">
.maincard
  margin-bottom: 1px
  min-height: 90px
  background-color: transparent !important
.maincard2
  margin-bottom: 1px
  min-height: 50px
  background-color: transparent !important
.maincard .card-body
  background: #fff
  border-radius: 5px
  padding: 15px !important
  .fw-bold
    font-weight: 600
  .material-icons
    font-size: 3em
    color: #fff
    cursor: pointer
.maincard .card-body.border-color-blue
  background: #0061f2
  color: #fff
.maincard .card-body.border-color-red
  background: #d92550
  color: #fff
.maincard .card-body.border-color-yellow
  background: #f7b924
  color: #fff
.maincard .card-body.border-color-green
  background: #3ac47d
  color: #fff

</style>